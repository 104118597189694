import React from 'react';
import styles from './Results.module.scss';
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/Spinner';
import { isLanguageSupported } from 'utils';
import { motion } from 'framer-motion';
import { ReactComponent as Info } from 'assets/info_icon.svg';

const spring = {
	type: 'spring',
	damping: 30,
	stiffness: 140,
	duration: 0.3,
};

const Results = ({ results, isAutomaticDetectionEnabled }) => {
	const { t } = useTranslation();
	return (
		<>
			<div className={styles.Results}>
				{isAutomaticDetectionEnabled && (
					<>
						<motion.div layout transition={spring} className={styles.Result}>
							<p>{t('results.label_language')}</p>
							<div>
								<Spinner loading={results.language.loading} />
								{!results.language.loading && !results.language.loadingFailed && !results.language.language && (
									<span>-</span>
								)}
								{!results.language.loading && !results.language.loadingFailed && results.language.language && (
									<span className={`${styles.Result_Language}`}>
										<span
											className={`${isLanguageSupported(results.language.language) ? 'black' : ''}`}
											style={{
												textTransform: 'uppercase',
												fontSize: isLanguageSupported(results.language.language) ? 20 : 14,
											}}
										>
											{results.language.language}
										</span>
										{!isLanguageSupported(results.language.language) && (
											<span style={{ fontSize: 14 }}> - {t('results.language_not_supported')}</span>
										)}
									</span>
								)}
								{results.language.loadingFailed && <span className="negative">Error</span>}
								<div data-tip={t('results.info_language')} data-for="languageTooltip" className={styles.Result_Info}>
									<Info />
								</div>
							</div>
						</motion.div>
						<ReactTooltip
							getContent={(dataTip) => `${dataTip}`}
							border
							className="tooltip"
							delayShow={100}
							id="languageTooltip"
							effect="solid"
							place="top"
						/>
					</>
				)}
				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_sentiment')}</p>
					<div>
						<Spinner loading={results.sentiment.loading} />
						{!results.sentiment.loading && !results.sentiment.loadingFailed && !results.sentiment.data && (
							<span>-</span>
						)}
						{!results.sentiment.loading && !results.sentiment.loadingFailed && results.sentiment.data && (
							<div className={styles.Result_Value}>
								<span className={`${results.sentiment.data.prediction}`}>{results.sentiment.data.prediction}</span>

								<span className={`${styles.Probability}`}>
									Probability: {Math.round(results.sentiment.data.probability * 100).toFixed(0)}%
								</span>
							</div>
						)}
						{results.sentiment.loadingFailed && <span className="negative">Error</span>}
						<div data-tip={t('results.info_sentiment')} data-for="resultTooltip" className={styles.Result_Info}>
							<Info />
						</div>
					</div>
				</motion.div>

				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_actionseeking')}</p>
					<div>
						<Spinner loading={results.bua.loading} />
						{!results.bua.loading && !results.bua.loadingFailed && !results.bua.actionSeeking && <span>-</span>}
						{!results.bua.loading && !results.bua.loadingFailed && results.bua.actionSeeking && (
							<div className={styles.Result_Value}>
								<span className={`${results.bua.actionSeeking.prediction === 'yes' ? 'black' : ''}`}>
									{results.bua.actionSeeking.prediction}
								</span>
								{results.bua.actionSeeking.probability != null && (
									<span className={`${styles.Probability}`}>
										Probability: {Math.round(results.bua.actionSeeking.probability * 100).toFixed(0)}%
									</span>
								)}
							</div>
						)}
						{results.bua.loadingFailed && <span className="negative">Error</span>}
						<div data-tip={t('results.info_actionseeking')} data-for="resultTooltip" className={styles.Result_Info}>
							<Info />
						</div>
					</div>
				</motion.div>
				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_informationseeking')}</p>
					<div>
						<Spinner loading={results.bua.loading} />
						{!results.bua.loading && !results.bua.loadingFailed && !results.bua.informationSeeking && <span>-</span>}
						{!results.bua.loading && !results.bua.loadingFailed && results.bua.informationSeeking && (
							<div className={styles.Result_Value}>
								<span className={`${results.bua.informationSeeking.prediction === 'yes' ? 'black' : ''}`}>
									{results.bua.informationSeeking.prediction}
								</span>
								{results.bua.informationSeeking.probability != null && (
									<span className={`${styles.Probability}`}>
										Probability: {Math.round(results.bua.informationSeeking.probability * 100).toFixed(0)}%
									</span>
								)}
							</div>
						)}
						{results.bua.loadingFailed && <span className="negative">Error</span>}
						<div
							data-tip={t('results.info_informationseeking')}
							data-for="resultTooltip"
							className={styles.Result_Info}
						>
							<Info />
						</div>
					</div>
				</motion.div>
				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_factoriented')}</p>
					<div>
						<Spinner loading={results.bua.loading} />
						{!results.bua.loading && !results.bua.loadingFailed && !results.bua.factOriented && <span>-</span>}
						{!results.bua.loading && !results.bua.loadingFailed && results.bua.factOriented && (
							<div className={styles.Result_Value}>
								<span className={`${results.bua.factOriented.prediction === 'yes' ? 'black' : ''}`}>
									{results.bua.factOriented.prediction}
								</span>
								{results.bua.factOriented.probability != null && (
									<span className={`${styles.Probability}`}>
										Probability: {Math.round(results.bua.factOriented.probability * 100).toFixed(0)}%
									</span>
								)}
							</div>
						)}
						{results.bua.loadingFailed && <span className="negative">Error</span>}
						<div data-tip={t('results.info_factoriented')} data-for="resultTooltip" className={styles.Result_Info}>
							<Info />
						</div>
					</div>
				</motion.div>
				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_selfreveiling')}</p>
					<div>
						<Spinner loading={results.bua.loading} />
						{!results.bua.loading && !results.bua.loadingFailed && !results.bua.selfRevealing && <span>-</span>}
						{!results.bua.loading && !results.bua.loadingFailed && results.bua.selfRevealing && (
							<div className={styles.Result_Value}>
								<span className={`${results.bua.selfRevealing.prediction === 'yes' ? 'black' : ''}`}>
									{results.bua.selfRevealing.prediction}
								</span>
								{results.bua.selfRevealing.probability != null && (
									<span className={`${styles.Probability}`}>
										Probability: {Math.round(results.bua.selfRevealing.probability * 100).toFixed(0)}%
									</span>
								)}
							</div>
						)}
						{results.bua.loadingFailed && <span className="negative">Error</span>}
						<div data-tip={t('results.info_selfreveiling')} data-for="resultTooltip" className={styles.Result_Info}>
							<Info />
						</div>
					</div>
				</motion.div>

				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_emotion')}</p>
					<div>
						<Spinner loading={results.emotion.loading} />
						{!results.emotion.loading && !results.emotion.loadingFailed && !results.emotion.data && <span>-</span>}
						{!results.emotion.loading &&
							!results.emotion.loadingFailed &&
							results.emotion.data &&
							results.emotion.data.prediction && (
								<div className={styles.Result_Value}>
									<span className={`black`}>{results.emotion.data.prediction}</span>

									<span className={`${styles.Probability}`}>
										Probability: {Math.round(results.emotion.data.probability * 100).toFixed(0)}%
									</span>
								</div>
							)}
						{!results.emotion.loading &&
							!results.emotion.loadingFailed &&
							results.emotion.data &&
							!results.emotion.data.prediction && <span>No Emotion</span>}
						{results.emotion.loadingFailed && <span className="negative">Error</span>}
						<div data-tip={t('results.info_emotion')} data-for="resultTooltip" className={styles.Result_Info}>
							<Info />
						</div>
					</div>
				</motion.div>

				<motion.div layout transition={spring} className={styles.Result}>
					<p>{t('results.label_personalitytrait')}</p>
					<div>
						<Spinner loading={results.personality.loading} />
						{!results.personality.loading && !results.personality.loadingFailed && !results.personality.data && (
							<span>-</span>
						)}
						{!results.personality.loading &&
							!results.personality.loadingFailed &&
							results.personality.data &&
							results.personality.data.prediction && (
								<div className={styles.Result_Value}>
									<span className={`black`}>{results.personality.data.prediction}</span>

									<span className={`${styles.Probability}`}>
										Probability: {Math.round(results.personality.data.probability * 100).toFixed(0)}%
									</span>
								</div>
							)}
						{!results.personality.loading &&
							!results.personality.loadingFailed &&
							results.personality.data &&
							!results.personality.data.prediction && <span>No Personality</span>}
						{results.personality.loadingFailed && <span className="negative">Error</span>}
						<div data-tip={t('results.info_personalitytrait')} data-for="resultTooltip" className={styles.Result_Info}>
							<Info />
						</div>
					</div>
				</motion.div>
			</div>
			<ReactTooltip
				getContent={(dataTip) => `${dataTip}`}
				border
				className="tooltip"
				delayShow={100}
				id="resultTooltip"
				effect="solid"
				place="top"
			/>
		</>
	);
};

export default Results;
