import React, { useEffect, useState, useContext } from 'react';
import styles from './Search.module.scss';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import enFlag from 'assets/en.svg';
import deFlag from 'assets/de.svg';
import esFlag from 'assets/es.png';
import { useDebounce, isLanguageSupported } from 'utils';
import axios from 'config/axios';
import * as realAxios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { ResultsContext } from 'context/results-context';

let detectLanguageCall;
let sentimentCall;
let emotionCall;
let buaCall;
let personalityCall;

const FLAG_SIZE = 24;

const Search = ({ autoFocus }) => {
	const { t } = useTranslation();
	const [apiLanguage, setAPILanguage] = useState('en');
	const [text, setText] = useState('');
	const debouncedSearchTerm = useDebounce(text, 400);
	const location = useLocation();
	const { state, dispatch } = useContext(ResultsContext);

	useEffect(() => {
		const filter = new URLSearchParams(location.search);
		if (filter && filter.get('text')) {
			setText(filter.get('text'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (debouncedSearchTerm?.trim()) {
			state?.automaticDetection ? onLanguageDetect() : onSearch(apiLanguage);
		} else {
			dispatch({ type: 'CLEAR_DASHBOARD' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearchTerm]);

	const loadSentiment = async (language = null) => {
		if (sentimentCall) {
			sentimentCall.cancel();
		}
		sentimentCall = realAxios.CancelToken.source();
		dispatch({ type: 'LOAD_SENTIMENT' });
		const body = [
			{
				id: '',
				language: language || state.language.language,
				text,
			},
		];
		try {
			const response = await axios.post('sentiment', body, { cancelToken: sentimentCall.token });
			dispatch({ type: 'LOAD_SENTIMENT_SUCCESS', payload: response.data });
		} catch (err) {
			if (!realAxios.isCancel(err)) {
				dispatch({ type: 'LOAD_SENTIMENT_FAIL' });
			}
		}
	};

	const loadEmotion = async (language = null) => {
		if (emotionCall) {
			emotionCall.cancel();
		}
		emotionCall = realAxios.CancelToken.source();
		dispatch({ type: 'LOAD_EMOTION' });
		const body = [
			{
				id: '',
				language: language || state.language.language,
				text,
			},
		];
		try {
			const response = await axios.post('ekman-emotion', body, { cancelToken: emotionCall.token });
			dispatch({ type: 'LOAD_EMOTION_SUCCESS', payload: response.data });
		} catch (err) {
			if (!realAxios.isCancel(err)) {
				dispatch({ type: 'LOAD_EMOTION_FAIL' });
			}
		}
	};

	const loadBuas = async (language = null) => {
		if (buaCall) {
			buaCall.cancel();
		}
		buaCall = realAxios.CancelToken.source();
		dispatch({ type: 'LOAD_BUA' });
		const body = [
			{
				id: '',
				language: language || state.language.language,
				text,
			},
		];
		try {
			const response = await axios.post('communication', body, { cancelToken: buaCall.token });
			dispatch({
				type: 'LOAD_BUA_SUCCESS',
				payload: response.data,
			});
		} catch (err) {
			if (!realAxios.isCancel(err)) {
				dispatch({ type: 'LOAD_BUA_FAIL' });
			}
		}
	};

	const loadPersonality = async (language = null) => {
		if (personalityCall) {
			personalityCall.cancel();
		}
		personalityCall = realAxios.CancelToken.source();
		dispatch({ type: 'LOAD_PERSONALITY' });
		const body = [
			{
				id: '',
				language: language || state.language.language,
				text,
			},
		];
		try {
			const response = await axios.post('personality', body, { cancelToken: personalityCall.token });
			dispatch({ type: 'LOAD_PERSONALITY_SUCCESS', payload: response.data });
		} catch (err) {
			if (!realAxios.isCancel(err)) {
				dispatch({ type: 'LOAD_PERSONALITY_FAIL' });
			}
		}
	};

	const onLanguageDetect = async () => {
		if (detectLanguageCall) {
			detectLanguageCall.cancel();
		}
		detectLanguageCall = realAxios.CancelToken.source();
		dispatch({ type: 'CLEAR_RESULTS' });
		dispatch({ type: 'DETECT_LANGUAGE' });
		const body = [
			{
				id: '',
				language: '',
				text,
			},
		];

		try {
			const response = await axios.post('language-detection', body, { cancelToken: detectLanguageCall.token });
			const language = response.data[0].detected_language;
			dispatch({ type: 'DETECT_LANGUAGE_SUCCESS', payload: language });
			if (isLanguageSupported(language)) {
				onSearch(language);
			}
		} catch (err) {
			if (!realAxios.isCancel(err)) {
				dispatch({ type: 'DETECT_LANGUAGE_FAIL' });
			}
		}
	};

	const onSearch = async (language = null) => {
		dispatch({ type: 'CLEAR_RESULTS' });
		loadSentiment(language);
		loadEmotion(language);
		loadBuas(language);
		loadPersonality(language);
	};

	const onTextChange = (event) => {
		setText(event.target.value);
	};

	const onClearText = () => {
		setText('');
		detectLanguageCall && detectLanguageCall.cancel();
		sentimentCall && sentimentCall.cancel();
		emotionCall && emotionCall.cancel();
		buaCall && buaCall.cancel();
		personalityCall && personalityCall.cancel();
	};

	const onLanguageDetectionChange = (event) => {
		const value = event.target.checked;
		if (value) {
			dispatch({ type: 'CLEAR_DASHBOARD' });
			text?.trim() && onLanguageDetect();
		} else {
			text?.trim() && onSearch(apiLanguage);
		}

		dispatch({ type: 'SET_AUTO_LANGUAGE_DETECT', payload: value });
	};

	const onChangeAPILanguage = (event) => {
		const language = event.target.value;
		setAPILanguage(language);
		text?.trim() && onSearch(language);
	};

	return (
		<>
			<div className={styles.Search_Textarea}>
				{text && <button onClick={onClearText}>&#x2573;</button>}
				<textarea
					onChange={onTextChange}
					value={text}
					placeholder={t('dashboard.input_area_placeholder')}
					autoFocus={autoFocus}
				></textarea>
			</div>
			<div className={styles.Search_FlagsLanguage}>
				<div className={styles.Search_Flags}>
					<p className={`${styles.Search_FlagsTitle}`}>{t('dashboard.supported_languages')}</p>
					<div>
						<img alt="English flag" src={enFlag} />
						<img alt="German flag" src={deFlag} />
						<img alt="Spanish flag" src={esFlag} />
					</div>
				</div>
				<div className={styles.Search_LanguageSwitch}>
					<span>{t('dashboard.automatic_language_detection')}</span>
					<div className={styles.Search_LanguageContainer}>
						<div style={{ position: 'relative', width: 'fit-content' }}>
							<label className="checker">
								<input
									onChange={onLanguageDetectionChange}
									checked={state?.automaticDetection}
									className="checkbox"
									type="checkbox"
								/>
								<div className="check-bg"></div>
								<div className="checkmark">
									<svg viewBox="0 0 100 100">
										<path
											d="M20,55 L40,75 L77,27"
											fill="none"
											stroke="#FFF"
											strokeWidth="15"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</div>
							</label>
						</div>
						<AnimatePresence>
							{!state?.automaticDetection && (
								<motion.div
									initial={{ opacity: 0 }}
									animate={{ opacity: 1 }}
									exit={{ opacity: 0 }}
									className={styles.Search_LanguagePicker}
								>
									<Select
										disableUnderline
										value={apiLanguage}
										onChange={onChangeAPILanguage}
										inputProps={{
											name: 'language',
											id: 'language-select',
										}}
									>
										<MenuItem value="en">
											<img alt="English flag" style={{ width: FLAG_SIZE, height: FLAG_SIZE }} src={enFlag} />
										</MenuItem>
										<MenuItem value="de">
											<img alt="German flag" style={{ width: FLAG_SIZE, height: FLAG_SIZE }} src={deFlag} />
										</MenuItem>
										<MenuItem value="es">
											<img alt="Spanish flag" style={{ width: FLAG_SIZE, height: FLAG_SIZE }} src={esFlag} />
										</MenuItem>
									</Select>
								</motion.div>
							)}
						</AnimatePresence>
					</div>
				</div>
			</div>
		</>
	);
};

export default Search;
