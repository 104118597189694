import React, { useReducer } from 'react';

const initialState = {
	automaticDetection: true,
	language: {
		loading: false,
		loadingFailed: false,
		language: null,
	},
	sentiment: {
		loading: false,
		loadingFailed: false,
		data: null,
	},
	emotion: {
		loading: false,
		loadingFailed: false,
		data: null,
	},
	bua: {
		loading: false,
		loadingFailed: false,
		actionSeeking: null,
		informationSeeking: null,
		factOriented: null,
		selfRevealing: null,
	},
	personality: {
		loading: false,
		loadingFailed: false,
		data: null,
	},
};

const reducer = (state, action) => {
	switch (action.type) {
		case 'SET_AUTO_LANGUAGE_DETECT':
			return {
				...state,
				automaticDetection: action.payload,
			};

		case 'DETECT_LANGUAGE':
			return {
				...state,
				language: {
					...state.language,
					loading: true,
					loadingFailed: false,
					language: null,
				},
			};

		case 'DETECT_LANGUAGE_SUCCESS': {
			const language = action.payload;
			return {
				...state,
				language: {
					...state.language,
					loading: false,
					loadingFailed: false,
					language,
				},
			};
		}

		case 'DETECT_LANGUAGE_FAIL': {
			return { ...state, language: { ...state.language, loadingFailed: true, loading: false } };
		}

		case 'LOAD_SENTIMENT':
			return {
				...state,
				sentiment: {
					...state.sentiment,
					loading: true,
					loadingFailed: false,
					data: null,
				},
			};

		case 'LOAD_SENTIMENT_SUCCESS': {
			const data = action.payload[0].predictions[0];
			return {
				...state,
				sentiment: {
					...state.sentiment,
					loading: false,
					loadingFailed: false,
					data,
				},
			};
		}

		case 'LOAD_SENTIMENT_FAIL': {
			return { ...state, sentiment: { ...state.sentiment, loadingFailed: true, loading: false } };
		}

		case 'LOAD_EMOTION':
			return {
				...state,
				emotion: {
					...state.emotion,
					loading: true,
					loadingFailed: false,
					data: null,
				},
			};

		case 'LOAD_EMOTION_SUCCESS': {
			const data = action.payload[0].predictions[0];
			return {
				...state,
				emotion: {
					...state.emotion,
					loading: false,
					loadingFailed: false,
					data,
				},
			};
		}

		case 'LOAD_EMOTION_FAIL': {
			return { ...state, emotion: { ...state.emotion, loadingFailed: true, loading: false } };
		}

		case 'LOAD_BUA':
			return {
				...state,
				bua: {
					...state.bua,
					loading: true,
					loadingFailed: false,
				},
			};

		case 'LOAD_BUA_SUCCESS': {
			let actionSeeking = 'No';
			let informationSeeking = 'No';
			let factOriented = 'No';
			let selfRevealing = 'No';

			let actionSeekingProbability = null;
			let informationSeekingProbability = null;
			let factOrientedProbability = null;
			let selfRevealingProbability = null;

			const data = action.payload[0].predictions;

			data.forEach((value) => {
				if (value.prediction === 'fact-oriented') {
					factOriented = 'yes';
					factOrientedProbability = value.probability;
				}
				if (value.prediction === 'self-revealing') {
					selfRevealing = 'yes';
					selfRevealingProbability = value.probability;
				}
				if (value.prediction === 'action-seeking') {
					actionSeeking = 'yes';
					actionSeekingProbability = value.probability;
				}
				if (value.prediction === 'information-seeking') {
					informationSeeking = 'yes';
					informationSeekingProbability = value.probability;
				}
			});

			return {
				...state,
				bua: {
					...state.bua,
					loading: false,
					loadingFailed: false,
					actionSeeking: {
						prediction: actionSeeking,
						probability: actionSeekingProbability,
					},
					informationSeeking: {
						prediction: informationSeeking,
						probability: informationSeekingProbability,
					},
					factOriented: {
						prediction: factOriented,
						probability: factOrientedProbability,
					},
					selfRevealing: {
						prediction: selfRevealing,
						probability: selfRevealingProbability,
					},
				},
			};
		}

		case 'LOAD_BUA_FAIL': {
			return { ...state, bua: { ...state.bua, loadingFailed: true, loading: false } };
		}

		case 'LOAD_PERSONALITY':
			return {
				...state,
				personality: {
					...state.personality,
					loading: true,
					loadingFailed: false,
					data: null,
				},
			};

		case 'LOAD_PERSONALITY_SUCCESS': {
			const data = action.payload[0].predictions[0];
			return {
				...state,
				personality: {
					...state.personality,
					loading: false,
					loadingFailed: false,
					data,
				},
			};
		}

		case 'LOAD_PERSONALITY_FAIL': {
			return { ...state, personality: { ...state.personality, loadingFailed: true, loading: false } };
		}

		case 'CLEAR_DASHBOARD':
			return {
				...state,
				language: initialState.language,
				sentiment: initialState.sentiment,
				bua: initialState.bua,
				emotion: initialState.emotion,
				personality: initialState.personality,
			};

		case 'CLEAR_RESULTS':
			return {
				...state,
				sentiment: initialState.sentiment,
				bua: initialState.bua,
				emotion: initialState.emotion,
				personality: initialState.personality,
			};

		default:
			return state;
	}
};

const ResultsContext = React.createContext();

const ResultsProvider = (props) => {
	const [results, dispatch] = useReducer(reducer, initialState);

	return (
		<ResultsContext.Provider
			value={{
				state: results,
				dispatch: dispatch,
			}}
		>
			{props.children}
		</ResultsContext.Provider>
	);
};

export { ResultsProvider, ResultsContext };
