import React from 'react';
import ReactDOM from 'react-dom';
import 'config/i18n';
import { App } from 'screens/App';
import 'scss/index.scss';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<>
		<App />
	</>,
	document.getElementById('root')
);

serviceWorker.unregister();
