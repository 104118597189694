import React, { useState } from 'react';
import styles from './Layout.module.scss';
import { Header } from 'components/Header';
import Routes from 'config/routes';
import { Footer } from 'components/Footer';
import { Drawer } from 'components/Drawer';
import { useLocation } from 'react-router-dom';

const Layout = () => {
	const [drawerState, setDrawerState] = useState(false);
	const location = useLocation();

	const onDrawerStateChange = (state) => {
		setDrawerState(state);
	};

	const exclusionArray = ['/demo'];

	return (
		<>
			{exclusionArray.indexOf(location.pathname) < 0 && (
				<Drawer open={drawerState} onBackdropClick={onDrawerStateChange} />
			)}
			{exclusionArray.indexOf(location.pathname) < 0 && <Header onMenuButtonClick={() => onDrawerStateChange(true)} />}
			<div
				className={exclusionArray.indexOf(location.pathname) < 0 ? `${styles.Layout_Content} main-content-wrapper` : ''}
			>
				<Routes />
			</div>
			{exclusionArray.indexOf(location.pathname) < 0 && <Footer />}
		</>
	);
};

export default Layout;
