import React from 'react';
import styles from './ContactUs.module.scss';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

const ContactUs = () => {
	const { t } = useTranslation();

	const onNavigateToDevelopers = () => {
		window.open('https://www.symanto.com/get-in-touch/', '_blank');
	};

	return (
		<div className={styles.ContactUs}>
			<p>{t('dashboard.request_key_interest')}</p>
			<motion.div whileTap={{ scale: 0.95 }}>
				<Button onClick={onNavigateToDevelopers} className="default-btn" color="default">
					{t('drawer.contact_us')}
				</Button>
			</motion.div>
		</div>
	);
};

export default ContactUs;
