import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from 'locales/en/translation.json';
import translationDE from 'locales/de/translation.json';
import translationES from 'locales/es/translation.json';

const resources = {
	en: {
		translation: translationEN,
	},
	de: {
		translation: translationDE,
	},
	es: {
		translation: translationES,
	},
};

// const localStorageLanguage = window.localStorage.getItem('i18nextLng');
const browserLanguage = navigator.language || navigator.userLanguage;

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: browserLanguage,
		fallbackLng: 'en',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
