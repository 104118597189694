import instance from 'config/axios';
import * as axios from 'axios';

const detectLanguage = () => {
	let call;
	return (url, body) => {
		if (call) {
			call.cancel('Only one request allowed at a time.');
		}
		call = axios.CancelToken.source();
		return instance.post(url, body, {
			cancelToken: call.token,
		});
	};
};

export const detectLanguageAPI = detectLanguage();
