import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
	return (
		<div className={styles.Footer}>
			<p>© {new Date().getFullYear()} Symanto Research GmbH & Co. KG</p>
			<span>|</span>
			<a target="_blank" rel="noopener noreferrer" href="https://www.symanto.com/impressum/">
				Impressum
			</a>
		</div>
	);
};

export default Footer;
