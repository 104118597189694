import React, { useState, useEffect } from 'react';
import styles from './Drawer.module.scss';
import logo from 'assets/logo.svg';
import { useTranslation } from 'react-i18next';

const Drawer = ({ open, onBackdropClick }) => {
	const { t } = useTranslation();
	const [isChrome, setIsChrome] = useState(false);
	const [isFirefox, setIsFirefox] = useState(false);

	const chromeExtURL =
		'https://chrome.google.com/webstore/detail/ai-powered-text-insights/ncnfjhmiglgmjokechbbbflbcnijbelk';
	const firefoxExtURL = 'https://addons.mozilla.org/en-US/firefox/addon/ai-powered-text-insights';
	const getInTouchURL = 'https://www.symanto.com/get-in-touch/';
	const symantoApiURL = 'https://developers.symanto.com/';
	const apiDocumentationURL = 'https://symanto-research.github.io/symanto-docs';

	useEffect(() => {
		const isChromium = window.chrome;
		const winNav = window.navigator;
		const vendorName = winNav.vendor;
		const isOpera = typeof window.opr !== 'undefined';
		const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
		const isIOSChrome = winNav.userAgent.match('CriOS');

		setIsFirefox(winNav.userAgent.indexOf('Firefox') > -1);

		if (isIOSChrome) {
			setIsChrome(true);
		}

		if (
			isChromium !== null &&
			typeof isChromium !== 'undefined' &&
			vendorName === 'Google Inc.' &&
			isOpera === false &&
			isIEedge === false
		) {
			setIsChrome(true);
		}
	}, []);

	const onBackdropClickHandler = () => {
		onBackdropClick(false);
	};

	let drawerClasses = styles.Drawer;
	if (open) {
		drawerClasses = `${styles.Drawer} ${styles.open}`;
	}

	return (
		<>
			{open && <div onClick={onBackdropClickHandler} className={styles.Drawer_Backdrop}></div>}
			<div className={drawerClasses}>
				<img alt="logo" className={styles.Drawer_Logo} src={logo} />
				<nav className={styles.Drawer_Nav}>
					<ul>
						<li>
							<a target="_blank" rel="noopener noreferrer" href={symantoApiURL}>
								{t('drawer.about_symanto')}
							</a>
						</li>
						<li>
							<a target="_blank" rel="noopener noreferrer" href={apiDocumentationURL}>
								{t('drawer.api_documentation')}
							</a>
						</li>
						<li>
							<a target="_blank" rel="noopener noreferrer" href={getInTouchURL}>
								{t('drawer.contact_us')}
							</a>
						</li>
						{isChrome && (
							<li>
								<a target="_blank" rel="noopener noreferrer" href={chromeExtURL}>
									{t('drawer.chrome_extension')}
								</a>
							</li>
						)}
						{isFirefox && (
							<li>
								<a target="_blank" rel="noopener noreferrer" href={firefoxExtURL}>
									{t('drawer.firefox_extension')}
								</a>
							</li>
						)}
					</ul>
				</nav>
			</div>
		</>
	);
};

export default Drawer;
