import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
	(config) => {
		const apiKey = process.env.REACT_APP_API_KEY;
		config.headers['x-api-key'] = apiKey;
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
