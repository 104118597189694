import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { AnimatePresence, motion } from 'framer-motion';

const GoogleAnalytics = () => {
	const [cookieAccepted, setCookieAccepted] = useState(null);

	const isInFrame = window.location !== window.parent.location;
	const COOKIE_NAME = 'dl-cookieConsent';

	const initializeReactGA = () => {
		ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
		ReactGA.pageview('Deep Learning Dashboard');
	};

	useEffect(() => {
		const cookie = Cookies.get(COOKIE_NAME);
		if (cookie === true) {
			setCookieAccepted(true);
			initializeReactGA();
		}
	}, []);

	const onCookieAccept = () => {
		Cookies.set(COOKIE_NAME, true);
		setCookieAccepted(true);
		initializeReactGA();
	};

	const acceptBtnStyle = {
		color: 'white',
		fontSize: '14px',
		background: '#1ba5c3',
		padding: '8px 15px',
		fontFamily: 'Lato',
	};

	const cookieContent = {
		background: '#1c223b',
		display: 'flex',
	};

	const cookieTextStyle = {
		fontFamily: 'Lato',
		letterSpacing: 0.3,
	};

	const cookieLearnMoreStyle = {
		color: 'white',
		cursor: 'pointer',
		marginLeft: 10,
		fontFamily: 'Lato',
		letterSpacing: 0.3,
	};

	return (
		<AnimatePresence>
			<>
				{cookieAccepted === null && !isInFrame && (
					<motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
						<CookieConsent
							onAccept={onCookieAccept}
							location="bottom"
							hideOnAccept
							buttonText="Accept"
							cookieName={COOKIE_NAME}
							sameSite="none"
							style={cookieContent}
							buttonStyle={acceptBtnStyle}
						>
							<div>
								<span style={cookieTextStyle}>
									This website uses cookies to ensure you get the best experience on our website.
								</span>{' '}
								<a
									style={cookieLearnMoreStyle}
									target="_blank"
									rel="noopener noreferrer"
									href="https://www.symanto.com/privacy-policy/"
								>
									Learn more
								</a>
							</div>
						</CookieConsent>
					</motion.div>
				)}
			</>
		</AnimatePresence>
	);
};

export default GoogleAnalytics;
