import React, { useEffect, useRef, useContext } from 'react';
import styles from './Dashboard.module.scss';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'utils';
import { Results } from 'components/Results';
import { Search } from 'components/Search';
import { ContactUs } from './components/ContactUs';
import { ResultsContext } from 'context/results-context';

const Dashboard = () => {
	const { t } = useTranslation();
	const size = useWindowSize();
	const dashboardBgRef = useRef(null);
	const dashboardContentRef = useRef(null);
	const { state } = useContext(ResultsContext);

	useEffect(() => {
		const dashboardBottom = dashboardContentRef?.current?.getBoundingClientRect()?.bottom;
		if (dashboardBottom && dashboardBgRef?.current) {
			dashboardBgRef.current.style.height = `${dashboardBottom - 130}px`;
		}
	}, [size]);

	return (
		<>
			<div ref={dashboardBgRef} className={styles.Dashboard_BG1}></div>
			<div className={styles.Dashboard}>
				<div className={styles.Dashboard_Title}>
					<h2>{t('dashboard.title')}</h2>
					<span>BETA</span>
				</div>
				<div ref={dashboardContentRef} className={styles.Dashboard_Content}>
					<div className={styles.Dashboard_Card}>
						<p className={`${styles.Dashboard_CardTitle}`}>{t('dashboard.input_area_title')}</p>
						<Search autoFocus={true} />
					</div>
					<div className={styles.Dashboard_Card}>
						<p className={`${styles.Dashboard_CardTitle}`}>{t('results.results_title')}</p>
						<Results isAutomaticDetectionEnabled={state?.automaticDetection} results={state} />
					</div>
				</div>
				<ContactUs />
			</div>
		</>
	);
};

export default Dashboard;
