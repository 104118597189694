import React from 'react';
import styles from './MenuButton.module.scss';

const MenuButton = ({ onClick }) => {
	const onMenuClick = () => {
		onClick();
	};

	return (
		<div onClick={onMenuClick} className={styles.MenuButton}>
			<span></span>
			<span></span>
			<span></span>
		</div>
	);
};

export default MenuButton;
