import React, { useEffect, useContext, useState } from 'react';
import styles from './Demo.module.scss';
import { useTranslation } from 'react-i18next';
import { Results } from 'components/Results';
import { ResultsContext } from 'context/results-context';
import { Search } from 'components/Search';

const Demo = () => {
	const { t, i18n } = useTranslation();
	const [oldLang, setOldLang] = useState('');
	const { state } = useContext(ResultsContext);

	useEffect(() => {
		setOldLang(i18n.language);
		i18n.changeLanguage('en');

		return () => {
			i18n.changeLanguage(oldLang);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className={styles.Dashboard_Content}>
				<div className={styles.Dashboard_Card}>
					<p className={`${styles.Dashboard_CardTitle}`}>{t('dashboard.input_area_title')}</p>
					<Search autoFocus={false} />
				</div>
				<div className={styles.Dashboard_Card}>
					<p className={`${styles.Dashboard_CardTitle}`}>{t('results.results_title')}</p>
					<Results isAutomaticDetectionEnabled={state?.automaticDetection} results={state} />
				</div>
			</div>
		</>
	);
};

export default Demo;
