import React from 'react';
import { Redirect } from 'react-router-dom';
import { Dashboard } from 'screens/Dashboard';
import { Switch, Route } from 'react-router-dom';
import { Demo } from 'screens/Demo';

const Routes = (props) => (
	<Switch>
		<Route path="/" exact component={Dashboard} />
		<Route path="/demo" component={Demo} isAuthed={true} />
		<Route path="*">
			<Redirect to="/" />
		</Route>
	</Switch>
);

export default Routes;
