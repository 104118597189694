import React from 'react';
import styles from './Header.module.scss';
import logo from 'assets/logo.svg';
import { MenuButton } from 'components/MenuButton';
import { Link } from 'react-router-dom';

const Header = ({ onMenuButtonClick }) => {
	const onMenuClick = () => {
		onMenuButtonClick();
	};

	return (
		<div className={styles.Header}>
			<div className={`${styles.Header_Content} main-content-wrapper`}>
				<div className={styles.Header_Menu}>
					<MenuButton onClick={onMenuClick} />
				</div>
				<Link to={'/'}>
					<img alt="logo" className={styles.Header_Logo} src={logo} />
				</Link>
			</div>
		</div>
	);
};

export default Header;
