import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styles from './App.module.scss';
import { Layout } from 'components/Layout';
import { GoogleAnalytics } from 'components/GoogleAnalytics';
import { ResultsProvider } from 'context/results-context';

const App = () => {
	return (
		<ResultsProvider>
			<Router>
				<div className={styles.App}>
					<Layout />
				</div>
			</Router>
			<GoogleAnalytics />
		</ResultsProvider>
	);
};

export default App;
